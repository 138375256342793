<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-tab
          :dense="true"
          type="pagetop"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
            />
          </template>
        </c-tab>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uploadship',
  data() {
    return {
      searchParam: {
        plantCd: null,
        dts: [],
      },
      tab: 'upload',
      editable: true,
      tabItems: [
        { name: 'upload', icon: 'groups', label: '하역/선적 현황', component: () => import(`${'./unload.vue'}`) },
        { name: 'ship', icon: 'list', label: '출하현황 관리', component: () => import(`${'./ship.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>
